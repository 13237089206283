<template>
  <div class="flex-grow flex-box vertical align-center">
    <div class="title-content">
      <a-card :bordered="false" title="门禁数据">
        <div class="piece-area-ctx flex-box align-center justify-between">
          <div class="piece-view">
            <div class="piece-view-value">{{ info.total }}</div>
            <div class="piece-view-title">门禁设备数量</div>
          </div>
          <div class="piece-view">
            <div class="piece-view-value">{{ info.online_count }}</div>
            <div class="piece-view-title">设备在线</div>
          </div>
          <div class="piece-view">
            <div class="piece-view-value">{{ info.online_rate }}</div>
            <div class="piece-view-title">设备在线率</div>
          </div>
        </div>
      </a-card>
    </div>
    <div class="flex-grow flex-box vertical content md mt-16">
      <div class="data-title">
        <div class="rec-title-text">共{{ pagination.total }}条记录</div>
        <a-form-model class="query-form" layout="inline">
          <a-form-model-item>
            <a-input
              v-model="form.name"
              placeholder="请输入设备名称、设备编号查询"
              allowClear
              style="width: 240px;"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              placeholder="选择办公室查询"
              show-search
              option-filter-prop="children"
              :options="officeOptions"
              v-model="form.office_id"
              @change="updateList"
              allowClear
              style="width: 180px;"
            >
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <a-space class="title-btn">
          <a-button @click="getList">查询</a-button>
        </a-space>
      </div>
      <div class="data-area">
        <a-table
          ref="dataTable"
          :columns="columns"
          row-key="id"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: h }"
          @change="handleTableChange"
        >
          <div slot="action" slot-scope="text, record" class="row-btn">
            <a class="txt-btn" @click.stop="editRow(record)">编辑</a>
            <a class="txt-btn" @click.stop="showInOutRecord(record)">查看进出记录</a>
            <a class="txt-btn" @click.stop="showAuth(record)" v-action:auth>权限管理</a>
          </div>
        </a-table>
      </div>
    </div>

    <!--    编辑-->
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="编辑"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-form-model
        class="item-form"
        ref="editForm"
        :model="formData"
        :rules="formRules"
        :labelCol="modalForm.labelCol"
        :wrapperCol="modalForm.wrapperCol"
        :layout="modalForm.layout"
        :labelAlign="modalForm.labelAlign"
      >
        <a-form-model-item label="设备名称">
          <div>{{ formData.name }}</div>
        </a-form-model-item>
        <a-form-model-item label="设备编号">
          <div>{{ formData.num }}</div>
        </a-form-model-item>
        <a-form-model-item label="关联办公室" prop="office_id">
          <a-select
            placeholder="选择办公室"
            show-search
            option-filter-prop="children"
            :options="officeOptions"
            v-model="formData.office_id"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { clone, dataConvertOptions, getQuery, isEmpty } from "../../common/js/tool";
import { doorStatusOptions } from "../../common/constant/space";

export default {
  name: "AccessControl",
  data() {
    return {
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "设备名称", dataIndex: "name" },
        { title: "设备编号", dataIndex: "num" },
        {
          title: "在线状态",
          dataIndex: "status",
          customRender: (text) => {
            let item = doorStatusOptions.find((option) => option.value == text);
            return item ? <span style={{ color: item.color }}>{item.label}</span> : "-";
          },
        },
        {
          title: "所属办公室",
          dataIndex: "office",
          customRender: (text) => {
            return <span>{text?.name || "-"}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 220,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        name: function(form, key) {
          let res = "";
          let value = form[key];
          if (!isEmpty(value)) {
            res = `&filter[or][0][name][like]=${value}&filter[or][1][num][like]=${value}`;
          }
          return res;
        },
      },
      info: {
        total: 100,
        online_count: 10,
        online_rate: 20,
      },
      modalForm: { layout: "vertical", labelAlign: "left" },
      formRules: {
        office_id: [{ required: true, message: "请选择所属办公室", trigger: "change" }],
      },
      formData: {},
      visible: false,
      officeOptions: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getInfo();
    this.getList();
    this.getOffice();
  },
  methods: {
    getInfo() {
      this.$axios("/admin/smart-entrance/count").then((res) => {
        // res: [总数, 在线数]
        let total = Number(res[0]) || 0;
        let online_count = Number(res[1]) || 0;
        let online_rate = 0;
        if (total > 0) {
          online_rate = (online_count / total).toFixed(2) * 100;
        }
        this.info = {
          total,
          online_count,
          online_rate: online_rate + "%",
        };
      });
    },
    getOffice() {
      this.$axios({
        url: "/admin/smart-office",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 99999,
        },
      }).then((res) => {
        this.officeOptions = dataConvertOptions(res.data);
      });
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    dealList(list) {
      return list;
    },
    getList() {
      this.loading = true;
      let url = `/admin/smart-entrance?sort=-id&expand=office`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += getQuery.call(this, this.searchKeyType);
      this.$axios(url)
        .then((res) => {
          this.loading = false;
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    editRow(record) {
      this.formData = clone(record);
      this.visible = true;
    },
    cancel() {
      this.formData = {};
      this.visible = false;
    },
    confirm() {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = this.formData;
          this.$axios({
            url: `/admin/smart-entrance/${form.id}`,
            method: "PATCH",
            data: {
              office_id: form.office_id,
            },
          }).then(() => {
            this.$message.success("更新成功");
            this.visible = false;
            this.getList();
          });
        }
      });
    },
    showInOutRecord(record) {
      this.$router.push({ name: "AccessControlRecord", params: { id: record.id } });
    },
    showAuth(record) {
      this.$router.push({ name: "AccessControlAuth", params: { id: record.id } });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../common/less/safe.less");

.title-content {
  width: 1200px;
  flex-shrink: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}
.mt-16 {
  margin-top: 16px;
}
</style>
